<template>
  <div class="keyword-reply-editor">
    <div v-loading="loading" class="edit-form" v-bind:class="{'basic-shadow': !noBorder, 'hair-border-ccc': !noBorder}">
      <el-form
        ref="keywordReplyEditForm"
        size="mini"
        :model="wxAutoReply"
        label-suffix=":"
        :label-width="hiddenContentLabel ? '0px' : '100px'"
      >
        <el-form-item v-if="!hiddenTitle" label="规则名称" prop="title">
          <el-input
            v-model="wxAutoReply.title"
            style="width: 80%"
            placeholder="请输入规则名称"
          />
        </el-form-item>
        <div v-if="!hiddenKeyword">
          <el-form-item
              label="关键词"
              v-for="(item, index) of keywords"
              :key="item.id ? item.id : item.key"
            >
              <el-select
                v-model="item.matchModel"
                style="width: 19%; margin-right: 1%"
              >
                <el-option :value="1" label="半匹配"></el-option>
                <el-option :value="2" label="全匹配"></el-option>
                <el-option :value="3" label="正则"></el-option>
                <el-option :value="4" label="BeanShell"></el-option>
              </el-select>
              <el-input
                v-model="item.keyword"
                style="width: 60%"
                placeholder="请输入关键字"
              />
              <i class="el-icon-plus plus-icon basic-shadow" @click="addKeyword" />
              <i
                v-if="index > 0"
                class="el-icon-close plus-icon basic-shadow"
                @click="removeFormArr(keywords, item)"
              />
          </el-form-item>
        </div>
        
        <el-form-item v-if="!hiddenReplyMethod" label="回复方式" prop="replyMethod">
          <el-radio-group v-model="wxAutoReply.replyMethod">
            <el-radio :label="1">随机一条</el-radio>
            <el-radio :label="2">全部回复</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item :label="hiddenContentLabel ? '' : '回复内容'">
          <div
            v-for="(item, index) in details"
            :key="item.id ? item.id : item.key"
            class="content-list-item"
          >
            <component :is="itemComponentSelector(item)" :item="item" :index="index" @edit="editItem" @remove="removeItem" />
          </div>

          <div style="display: block">
            <el-button-group>
              <el-button
                type="info"
                icon="el-icon-document"
                plain
                :disabled="true"
                @click="addNews"
                >已发表内容</el-button
              >
              <el-button
                type="info"
                icon="el-icon-chat-line-square"
                plain
                @click="addText"
                >文字</el-button
              >
              <el-button
                type="info"
                icon="el-icon-picture-outline"
                plain
                @click="addImage"
                >图片</el-button
              >
              <el-button
                type="info"
                icon="el-icon-microphone"
                plain
                :disabled="true"
                @click="addAudio"
                >音频</el-button
              >
              <el-button
                type="info"
                icon="el-icon-video-camera-solid"
                plain
                :disabled="true"
                @click="addVideo"
                >视频</el-button
              >
            </el-button-group>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button :loading="saving" type="success" @click="save"
            >保存</el-button
          >
          <el-button type="info" plain @click="cancel">取消</el-button>
        </el-form-item>
      </el-form>
    </div>

    <el-dialog
      :visible.sync="textReplyDialog.show"
      title="添加文本回复"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      custom-class="text-reply-dialog"
    >
      <textarea
        v-model="textReplyDialog.value"
        ref="textReplyDiv"
        contenteditable="true"
        class="text-reply-editor"
      ></textarea>
      <div class="input-notice">
        <span>
          还可以输入{{
            300 - (textReplyDialog.value ? textReplyDialog.value : "").length
          }}字，按下Enter键换行
        </span>
      </div>
      <template #footer>
        <el-button type="primary" size="mini" @click="confrimTextReply"
          >确定</el-button
        >
      </template>
    </el-dialog>

    <el-dialog
      :visible.sync="mediaReplyDialog.show"
      title="添加图片回复"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      destroy-on-close
      custom-class="image-reply-dialog"
    >

      <wx-image-mat-selector @select="selectedMedia" />
     
      <template #footer>
        <el-button type="primary" size="mini" @click="confrimMediaReply" >确定</el-button
        >
      </template>
    </el-dialog>
  </div>
</template>

<script>
const MSG_TYPE_NEWS = 1,
  MSG_TYPE_TEXT = 2,
  MSG_TYPE_IMAGE = 3,
  MSG_TYPE_AUDIO = 4,
  MSG_TYPE_VIDEO = 5;

export default {
  name: "keyword-reply-editor",
  props: {
    currentId: {
      type: Number,
      required: false,
    },
    hiddenTitle: {
      type: Boolean,
      default: false
    },
    hiddenReplyMethod: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    hiddenContentLabel: {
      type: Boolean,
      default: false
    },
    hiddenKeyword: {
      type: Boolean,
      default: false
    },
    replyType: {
      type: Number,
      required: false
    }
  },
  data() {
    return {
      loading: false,
      saving: false,
      textReplyDialog: {
        show: false,
        value: "",
        index: -1
      },
      mediaReplyDialog: {
        show: false,
        value: '',
        index: -1
      },
      keywords: [
        {
          matchModel: 1,
          keyword: "",
          key: Date.now(),
        },
      ],
      wxAutoReply: {
        replyMethod: 1,
      },
      details: [],
    };
  },
  methods: {
    cancel() {
      this.$emit("cancel");
    },
    async save() {
      try {
        this.saving = true;
        let res = await this.$http.doApi(
          "fw-wx-auto-reply-saveFull",
          {},
          {
            keywords: this.keywords,
            wxAutoReply: {
              replyType: this.replyType,
              ...this.wxAutoReply
            },
            details: this.details,
          }
        );
        this.$emit("saved", res.val);
        this.$message.success("保存成功");
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
      }
    },
    addNews() {
      this.$message.info("暂不支持" + MSG_TYPE_NEWS);
    },
    addText() {
      this.textReplyDialog.show = true;
    },
    confrimTextReply() {
      if (this.textReplyDialog.index == -1) {
        // 添加
        this.details.push({
          msgType: MSG_TYPE_TEXT,
          content: this.textReplyDialog.value,
          key: Date.now(),
        });
      } else {
        // 修改
        this.details[this.textReplyDialog.index].content = this.textReplyDialog.value;
      }
      this.textReplyDialog.index = -1;
      this.textReplyDialog.value = "";
      this.textReplyDialog.show = false;
    },
    confrimMediaReply() {
      if (this.mediaReplyDialog.index == -1) {
        let item = this.mediaReplyDialog.item;
        item.key = Date.now();
        // 添加
        this.details.push(item);
      } else {
        // 修改
        let item = this.mediaReplyDialog.item;
        this.$set(this.details, this.mediaReplyDialog.index, item);
      }
      this.mediaReplyDialog.index = -1;
      this.mediaReplyDialog.item = null;
      this.mediaReplyDialog.show = false;
    },
    selectedMedia(mediaId) {
      this.mediaReplyDialog.item.content = mediaId;
    },
    editTextReply(item) {
      this.textReplyDialog.value = item.content;
      this.textReplyDialog.show = true;
      this.textReplyDialog.index = this.details.indexOf(item);
    },
    editImageReply(item) {
      this.mediaReplyDialog.item = {...item};
      this.mediaReplyDialog.show = true;
      this.mediaReplyDialog.index = this.details.indexOf(item);
    },
    editItem(item) {
      if (item.msgType === MSG_TYPE_TEXT) {
        this.editTextReply(item);
      } else if (item.msgType === MSG_TYPE_IMAGE) {
        this.editImageReply(item);
      }
    },
    removeItem(item) {
      this.removeFormArr(this.details, item);
    },
    addImage() {
      this.mediaReplyDialog.item = {
        msgType: MSG_TYPE_IMAGE,
        key: Date.now()
      };
      this.mediaReplyDialog.fieldLabel = '图片素材';
      this.mediaReplyDialog.codeDomain = 'wxImgMaterial-code';
      this.mediaReplyDialog.show = true;
    },
    addAudio() {
      this.$message.info("暂不支持" + MSG_TYPE_AUDIO);
    },
    addVideo() {
      this.$message.info("暂不支持" + MSG_TYPE_VIDEO);
    },
    addKeyword() {
      this.keywords.push({
        matchModel: 1,
        keyword: "",
        key: Date.now(),
      });
    },
    removeFormArr(arr, item) {
      var index = arr.indexOf(item);
      if (index !== -1) {
        arr.splice(index, 1);
      }
    },
    async loadReplyDetail(id) {
      if (!id) {
        this.wxAutoReply = {
          replyMethod: 1,
        };
        this.keywords = [
          {
            matchModel: 1,
            keyword: "",
            key: Date.now(),
          },
        ];
        this.details = [];
        return;
      }
      this.loading = true;
      try {
        let combData = await this.$http.doApi("fw-wx-auto-reply-getFull", {
          id,
        });
        this.wxAutoReply = combData.wxAutoReply;
        this.keywords = combData.keywords;
        this.details = combData.details;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    itemComponentSelector(item) {
      if (item.msgType == MSG_TYPE_TEXT) {
        return 'text-reply-list-item';
      } else if (item.msgType === MSG_TYPE_IMAGE) {
        return 'image-reply-list-item';
      }
    }
  },
  watch: {
    currentId: {
      immediate: true,
      handler(val) {
        this.loadReplyDetail(val);
      },
    },
  },
  components: {
    'text-reply-list-item': () => import('./TextReplyListItem'),
    'image-reply-list-item': () => import('./ImageReplyListItem'),
    'wx-image-mat-selector': () => import('./ImageMaterialSelector')
  }
};
</script>

<style lang="less">
.keyword-reply-editor {
  
  .edit-form {
    padding-top: 1rem;
    border-radius: 10px;
    margin-bottom: 1rem;

    .plus-icon {
      border: @hair-border-ccc;
      width: 20px;
      height: 20px;
      border-radius: 10px;
      line-height: 20px;
      text-align: center;
      margin-left: 0.5rem;
      cursor: pointer;
    }

  }

  .number {
    margin-right: 1rem; 
  }
  
  .edit-btns {
    margin-left: 1rem;
    letter-spacing: 1rem;
  }

  .text-reply-dialog {
    .el-dialog__body {
      padding: 1rem 1.5rem;
    }

    .el-dialog__footer {
      padding-top: 0.5rem;
    }

    .text-reply-editor {
      width: calc(100% - 1rem);
      height: 300px;
      border: @hair-border-ccc;
      padding: 0.5rem;
      resize: none;
      outline: none;
      margin-bottom: 0px;
      display: block;
    }

    .input-notice {
      text-align: right;
      padding: 0.5rem;
      width: calc(100% - 1rem);
      border-top: 0px;
      border-left: @hair-border-ccc;
      border-bottom: @hair-border-ccc;
      border-right: @hair-border-ccc;
      margin-top: 0px;
    }
  }
}
</style>